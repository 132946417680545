export function Banner() {
  return (
    <section
      className='text-center py-20'
      style={{ backgroundColor: '#00adee' }}
      id='home'
    >
      <div className='container mx-auto px-6 flex flex-col lg:flex-row items-center'>
        <div style={{ maxWidth: '500px' }} className='lg:w-1/2'>
          <h2 className='text-5xl font-bold mb-4 text-white'>GO GP</h2>
          <p className='text-lg mb-8 text-white font-medium'>
            Plongez dans une expérience unique avec une application mobile prête
            à révolutionner votre quotidien.
          </p>
          <div className='flex justify-center space-x-4 mb-8 items-center'>
            <a
              className='text-white px-4 py-2 rounded'
              href='https://apps.apple.com/fr/app/go-gp/id6479958528'
            >
              <img src='./apple-store.png' width='150px' alt='Apple Store' />
            </a>
            <a
              className='text-white px-4 py-2 rounded'
              href='https://play.google.com/store/apps/details?id=www.gogp.com'
            >
              <img
                src='./google-play.png'
                width='150px'
                alt='Google Play Store'
              />
            </a>
          </div>
        </div>
        <div className='flex justify-center flex-1 lg:w-1/2'>
          <img
            alt='Mobile app screenshot'
            style={{ height: '500px' }}
            src='./iphone.png'
          />
        </div>
      </div>
    </section>
  );
}
