export function CGU() {
  return (
    <>
      <div className='max-w-2xl mx-auto mt-10 mb-10 p-4'>
        <h1 className='text-2xl font-bold mb-8'>
          Politique de Confidentialité de l'Application GOGP
        </h1>

        <h2 className='text-xl font-bold mt-8 mb-4'>Introduction</h2>
        <p className='text-sm leading-6 mb-4'>
          Bienvenue sur l'application GOGP. Nous nous engageons à protéger la
          confidentialité et la sécurité de vos informations personnelles. Cette
          politique de confidentialité explique comment nous collectons,
          utilisons, divulguons et protégeons vos informations lorsque vous
          utilisez notre application.
        </p>

        <h2 className='text-xl font-bold mt-8 mb-4'>Informations Collectées</h2>
        <p className='text-sm leading-6 mb-4'>
          Nous collectons les types d'informations suivants pour vous fournir
          une meilleure expérience utilisateur :
        </p>

        <h3 className='text-lg font-bold mt-6 mb-2'>
          Informations Personnelles
        </h3>
        <p className='text-sm leading-6 mb-4'>
          - <span className='font-bold'>Identifiants :</span> Prénom, Nom,
          Adresse e-mail, Mot de passe, Numéro de téléphone, Adresse.
          <br />- <span className='font-bold'>
            Identifiants de Compte :
          </span>{' '}
          Numéro d'utilisateur, type d'utilisateur, Code de sponsor.
          <br />- <span className='font-bold'>CIN ou Passeport :</span>{' '}
          Informations sur les documents fournis et leur statut de vérification.
          <br />- <span className='font-bold'>Photo de selfie :</span> Pour
          identifier les clients lors de la réservation.
        </p>

        <h3 className='text-lg font-bold mt-6 mb-2'>Informations Techniques</h3>
        <p className='text-sm leading-6 mb-4'>
          - <span className='font-bold'>Détails de Connexion :</span> Historique
          des connexions, Date de création du compte, Date de mise à jour du
          compte.
          <br />- <span className='font-bold'>Avatar :</span> Image de profil de
          l'utilisateur.
          <br />- <span className='font-bold'>Microphone :</span> Pour permettre
          aux utilisateurs d’envoyer des messages vocaux.
          <br />- <span className='font-bold'>Caméra :</span> Pour permettre aux
          utilisateurs d’envoyer des photos dans les conversations.
          <br />- <span className='font-bold'>Gallery :</span> Pour permettre
          aux utilisateurs d’envoyer des photos à partir de la galerie des
          images dans les conversations.
        </p>

        <h2 className='text-xl font-bold mt-8 mb-4'>
          Utilisation des Informations
        </h2>
        <p className='text-sm leading-6 mb-4'>
          Les informations que nous collectons sont utilisées pour :<br />
          - Fournir et gérer nos services.
          <br />
          - Vérifier et authentifier les utilisateurs.
          <br />
          - Améliorer et personnaliser votre expérience sur notre application.
          <br />
          - Communiquer avec vous, y compris l'envoi de notifications
          importantes.
          <br />
          - Assurer la sécurité et prévenir la fraude.
          <br />- Se conformer aux obligations légales et réglementaires.
        </p>

        <h2 className='text-xl font-bold mt-8 mb-4'>
          Partage des Informations
        </h2>
        <p className='text-sm leading-6 mb-4'>
          Nous ne partageons vos informations personnelles avec des tiers que
          dans les circonstances suivantes :<br />
          - Avec votre consentement explicite.
          <br />
          - Pour se conformer à des obligations légales ou réglementaires.
          <br />
          - Avec des prestataires de services qui nous aident à fournir et à
          améliorer nos services, sous réserve qu'ils respectent des obligations
          de confidentialité strictes.
          <br />- En cas de fusion, acquisition ou vente de tout ou partie de
          nos actifs, sous réserve que l'entité recevant les informations
          personnelles respecte cette politique de confidentialité.
        </p>

        <h2 className='text-xl font-bold mt-8 mb-4'>
          Sécurité des Informations
        </h2>
        <p className='text-sm leading-6 mb-4'>
          Nous mettons en œuvre des mesures de sécurité techniques et
          organisationnelles appropriées pour protéger vos informations
          personnelles contre toute perte, utilisation abusive, accès non
          autorisé, divulgation, altération ou destruction.
        </p>

        <h2 className='text-xl font-bold mt-8 mb-4'>Vos Droits</h2>
        <p className='text-sm leading-6 mb-4'>
          En tant qu'utilisateur, vous disposez des droits suivants concernant
          vos informations personnelles :<br />
          - Accéder à vos informations personnelles.
          <br />
          - Corriger toute information inexacte ou incomplète.
          <br />
          - Demander la suppression de vos informations personnelles, sous
          réserve des exigences légales et de nos besoins opérationnels.
          <br />
          - Retirer votre consentement à tout moment lorsque le traitement de
          vos informations est basé sur votre consentement.
          <br />- Vous opposer au traitement de vos informations pour des motifs
          légitimes.
        </p>

        <h2 className='text-xl font-bold mt-8 mb-4'>
          Modifications de cette Politique de Confidentialité
        </h2>
        <p className='text-sm leading-6 mb-4'>
          Nous nous réservons le droit de modifier cette politique de
          confidentialité à tout moment. Nous vous informerons de toute
          modification importante via l'application ou par e-mail.
        </p>

        <h2 className='text-xl font-bold mt-8 mb-4'>Contact</h2>
        <p className='text-sm leading-6 mb-4'>
          Si vous avez des questions ou des préoccupations concernant cette
          politique de confidentialité ou nos pratiques en matière de
          confidentialité, veuillez nous contacter à : contact@gogp.app
        </p>

        <h2 className='text-xl font-bold mt-8 mb-4'>Conclusion</h2>
        <p className='text-sm leading-6 mb-4'>
          En utilisant l'application GOGP, vous acceptez les termes de cette
          politique de confidentialité. Nous nous engageons à protéger vos
          informations personnelles et à être transparents quant à notre
          utilisation des données.
        </p>

        <p className='text-sm italic mt-8'>Dernière mise à jour : 19/07/2024</p>
      </div>
    </>
  );
}
