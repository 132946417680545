import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export function Demo() {
  return (
    <section className='py-20 bg-white overflow-hidden relative' id='demo'>
      <div className='container mx-auto px-6 text-center'>
        <h2 className='text-3xl font-bold mb-8'>Démonstration</h2>
        <div className='swiper-container'>
          <div className='swiper-wrapper'>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={'auto'}
              navigation
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              pagination={{ clickable: true }}
              scrollbar={false}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                <SwiperSlide key={item}>
                  <SwiperItem src={`./cap${item}.png`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

function SwiperItem({ src }: any) {
  return (
    <div className='swiper-slide'>
      <div className='bg-white p-6 rounded shadow'>
        <img
          alt='News image'
          className='mb-4'
          src={src}
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
    </div>
  );
}
