import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const MenuItems = [
  {
    label: 'Accueil',
    id: 'home',
  },
  {
    label: 'Les fonctionnalités',
    id: 'feature',
  },
  {
    label: 'Démo',
    id: 'demo',
  },
  {
    label: 'Faq',
    id: 'faq',
  },
  {
    label: 'Contact',
    id: 'contact',
  },
];
export function Header() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('#home');

  // Chemin de base pour les liens
  const basePath = location.pathname !== '/' ? '/' : '';
  useEffect(() => {
    const handleAnchorClick = (anchor: any) => {
      const targetElement = document.querySelector(anchor.getAttribute('href'));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
        // Met à jour l'URL sans recharger la page
        window.history.pushState(null, '', anchor.getAttribute('href'));
      }
    };

    document.querySelectorAll('a[href^="#"]').forEach((anchor: any) => {
      anchor.addEventListener('click', (e: any) => {
        e.preventDefault();
        handleAnchorClick(anchor);
        setActiveLink(anchor.getAttribute('href'));
        setIsMenuOpen(false);
      });
    });

    return () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.removeEventListener('click', (e) => {
          e.preventDefault();
        });
      });
    };
  }, []);

  return (
    <header className='bg-white shadow-md'>
      <div className='container mx-auto flex justify-between items-center py-6 px-6'>
        {/* Logo */}
        <div className='flex items-center'>
          <img src='../../logo.png' className='w-24 h-auto' alt='GO GP Logo' />
          <h1 className='ml-3 text-3xl font-extrabold text-gray-800'>GO GP</h1>
        </div>

        {/* Desktop Navigation */}
        <nav className='hidden md:flex space-x-8'>
          {MenuItems.map(({ label, id }) => (
            <a
              key={id}
              className={`${
                activeLink === `#${id}`
                  ? 'text-indigo-600 font-bold'
                  : 'text-gray-700'
              } font-medium hover:text-indigo-600 transition-colors`}
              href={`${basePath}#${id}`}
              onClick={() => setActiveLink(`#${id}`)}
            >
              {label}
            </a>
          ))}
        </nav>

        {/* Mobile Menu Button */}
        <div className='md:hidden'>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className='text-gray-700 focus:outline-none'
          >
            <i className='fas fa-bars text-2xl'></i>
          </button>
        </div>
      </div>

      {/* Mobile Navigation avec animation */}
      <div
        className={`md:hidden bg-white shadow-lg transition-all duration-500 ease-in-out transform ${
          isMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        } overflow-hidden`}
      >
        <nav className='flex flex-col space-y-1 px-4 py-2'>
          {MenuItems.map(({ label, id }) => (
            <a
              key={id}
              className={`${
                activeLink === `#${id}`
                  ? 'text-indigo-600 font-bold'
                  : 'text-gray-700'
              } block font-medium hover:bg-gray-100 hover:text-indigo-600 transition-colors px-4 py-2 rounded`}
              href={`${basePath}#${id}`}
              onClick={() => {
                setActiveLink(`#${id}`);
                setIsMenuOpen(false);
              }}
            >
              {label}
            </a>
          ))}
        </nav>
      </div>
    </header>
  );
}
