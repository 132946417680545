import { Accordion } from './Accordion';

export function Faq() {
  return (
    <section className='bg-gray-100 py-20' id='faq'>
      <div className='container mx-auto px-6'>
        <h2 className='text-3xl font-bold text-center mb-12'>FAQ</h2>
        <div className='flex flex-wrap justify-around items-start'>
          <div className='w-full md:w-1/2 lg:w-2/3'>
            <Accordion title='Qui sommes nous ?'>
              <p className='pb-2 text-justify'>
                Découvrez GoGp, une plateforme innovante qui connecte les
                expéditeurs de colis avec des voyageurs disposant d’espace libre
                dans leurs bagages. Ce concept repose sur les "GP" (Gratis
                Passengers ou Gratuité Partielle), un système initialement
                réservé aux employés des compagnies aériennes et à leurs
                proches, leur permettant de voyager à moindre coût ou
                gratuitement. Historiquement, les "GP" étaient utilisés par ces
                voyageurs pour transporter des colis informellement pour des
                proches ou des tiers, en échange d'une petite compensation.
              </p>
              <p className='pb-2 text-justify'>
                GoGp formalise cette pratique en créant un espace sécurisé où
                les expéditeurs peuvent facilement trouver des voyageurs prêts à
                rentabiliser l'espace inutilisé dans leurs bagages. Que vous
                souhaitiez envoyer un cadeau spécial ou expédier un colis à
                l'international, GoGp vous offre une solution abordable, sûre et
                pratique.
              </p>
              <p className='pb-2 text-justify'>
                Économisez sur les frais de livraison en profitant de cette
                économie collaborative. Grâce à GoGp, chaque espace inutilisé
                devient une opportunité.
              </p>
              <p className='pb-2 text-justify'>
                GoGp transforme la façon dont vous expédiez vos colis, en vous
                assurant simplicité, sécurité et flexibilité. Téléchargez
                maintenant et rejoignez une communauté de voyageurs et
                d'expéditeurs qui font la différence !
              </p>
            </Accordion>
            <Accordion title='Comment sont admis les GP?'>
              <p className='pb-2 text-justify'>
                Pour devenir GP et rejoindre la communauté GoGp, il vous suffit
                de télécharger notre application, de vous inscrire et de vous
                connecter. Assurez-vous ensuite de vérifier et de mettre à jour
                votre profil en fournissant les informations requises.
              </p>
              <p className='pb-2 text-justify'>
                Sur notre plateforme, chaque GP est identifié à l’aide de
                documents d’identification. Tout GP souhaitant apparaître sur
                notre plateforme doit passer par un processus d'identification
                rigoureux. Cela nous permet d'assurer une sécurité optimale pour
                nos clients.
              </p>
            </Accordion>

            <Accordion title='Comment trouver des GP?'>
              <p className='pb-2 text-justify'>
                Trouver un GP n'a jamais été aussi simple ! Connectez-vous à
                l'application, sélectionnez le trajet qui vous convient en
                fonction des descriptions des annonces publiées par les
                différents GP visibles depuis l'accueil. Une fois votre choix
                fait, accédez au profil du GP sélectionné, envoyez-lui un
                message avec les détails de votre colis et une photo. Ensuite,
                laissez la magie opérer : le GP choisira d’accepter ou non de
                rentrer en contact avec vous.
              </p>
            </Accordion>

            <Accordion title='Comment sécuriser mes colis?'>
              <p className='pb-2 text-justify'>
                Pour sécuriser votre colis lors du Co-colisage via notre
                plateforme, suivez ces étapes :
              </p>
              <p className='pb-2 text-justify'>
                <b>Prenez une Photo :</b> Avant l'expédition, prenez une photo
                claire et détaillée de votre colis.
              </p>
              <p className='pb-2 text-justify'>
                <b>Envoyez la Photo :</b> Transmettez cette photo au GP
                (voyageur) via notre plateforme pour enregistrer l'état du
                colis.
              </p>
              <p className='pb-2 text-justify'>
                <b>Vérification à la Réception :</b> À l’arrivée, le GP
                comparera le colis reçu avec la photo envoyée pour s’assurer de
                la conformité.
              </p>
              <p className='pb-2 text-justify'>
                Cette procédure permet de garantir que le colis que vous
                expédiez correspond bien à ce qui a été enregistré, offrant
                ainsi une sécurité accrue tout au long du transport.
              </p>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}
