export function Download() {
  return (
    <section className='bg-gradient-to-r from-purple-500 to-blue-500 text-white py-20'>
      <div className='container mx-auto px-6 text-center'>
        <h2 className='text-3xl font-bold mb-8'>
          Qu'attendez-vous ? Téléchargez maintenant !
        </h2>
        <div className='flex justify-center space-x-4 items-center'>
          <a
            className='text-white px-4 py-2 rounded'
            href='https://apps.apple.com/fr/app/go-gp/id6479958528'
          >
            <img src='./apple-store.png' width='150px' alt='Apple Store' />
          </a>
          <a
            className='text-white px-4 py-2 rounded'
            href='https://play.google.com/store/apps/details?id=www.gogp.com'
          >
            <img
              src='./google-play.png'
              width='150px'
              alt='Google Play Store'
            />
          </a>
        </div>
      </div>
    </section>
  );
}
