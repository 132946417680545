import { Banner } from '../components/Banner';
import { Contact } from '../components/Contact';
import { Demo } from '../components/Demo';
import { Download } from '../components/Download';
import { Faq } from '../components/Faq';
import { Features } from '../components/Features';
import { Slogan } from '../components/Slogan';
import { Statistics } from '../components/Statistics';

export function Home() {
  return (
    <>
      <Banner />
      <Statistics />
      <Features />
      <Slogan />
      <Demo />
      <Faq />
      <Download />
      <Contact />
    </>
  );
}
