export function Statistics() {
  return (
    <section className='bg-gray-100 py-20'>
      <div className='container mx-auto px-6 text-center'>
        <div className='flex justify-around'>
          <div>
            <h3 className='text-3xl font-bold'>+1M</h3>
            <p>Téléchargements</p>
          </div>
          <div>
            <h3 className='text-3xl font-bold'>+100</h3>
            <p>Clients</p>
          </div>
          <div>
            <h3 className='text-3xl font-bold'>+50</h3>
            <p>GP</p>
          </div>
        </div>
      </div>
    </section>
  );
}
