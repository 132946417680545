export function Slogan() {
  return (
    <section className='bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20'>
      <div className='container mx-auto px-6 text-center'>
        <h2 className='text-4xl font-bold mb-8 '>
          GO GP, l'application qui change votre quotidien
        </h2>
      </div>
    </section>
  );
}
