import { useState } from 'react';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
}

export function Accordion({ title, children }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='mb-6'>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='w-full text-left bg-white p-4 rounded shadow flex justify-between items-center'
      >
        <span className='font-bold'>{title}</span>
        <i
          className={`fas fa-chevron-down transition-transform duration-300 ${
            isOpen ? 'rotate-180' : ''
          }`}
        ></i>
      </button>
      {/* Animation de l'accordéon */}
      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        <div className='p-4 bg-white rounded italic text-sm'>{children}</div>
      </div>
    </div>
  );
}
