import 'swiper/css';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { CGU } from './pages/CGU';
import { AboutUs } from './pages/AboutUs';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='/cgu' element={<CGU />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default App;
