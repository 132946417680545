export function Features() {
  return (
    <section className='py-20 bg-white overflow-hidden' id='feature'>
      <div className='container mx-auto px-6'>
        <h2 className='text-3xl font-bold text-center mb-12'>
          Fonctionnalités
        </h2>
        <div className='flex flex-wrap justify-around items-start'>
          <div className='w-full md:w-1/2 lg:w-1/4 mb-12 text-center p-4'>
            <img
              alt='Feature image'
              className='mx-auto'
              style={{ width: '150px', height: 'auto' }}
              src='./loupe.png'
            />
            <h3 className='text-xl font-bold mt-6'>Trouver des GP</h3>
            <p className='mt-4 font-light text-sm text-justify'>
              Repérez des GP autour de vous en un instant ! Si aucun n’est
              disponible à votre point de départ, l’application vous propose
              automatiquement des options proches, avec la distance exacte pour
              ne rien rater. Simple, rapide et efficace !
            </p>
          </div>
          <div className='w-full md:w-1/2 lg:w-1/4 mb-12 text-center p-4'>
            <img
              alt='Feature image'
              className='mx-auto'
              style={{ width: '150px', height: 'auto' }}
              src='./securite.png'
            />
            <h3 className='text-xl font-bold mt-6'>Sécurité des colis</h3>
            <p className='mt-4 text-sm text-justify'>
              Vous avez la possibilité d'ajouter des photos à vos colis de même
              que le GP aussi dans le but d'apporter une securité pour le GP et
              également de garantir l'intégrité du colis.
            </p>
          </div>
          <div className='w-full md:w-1/2 lg:w-1/4 mb-12 text-center p-4'>
            <img
              alt='Feature image'
              className='mx-auto'
              style={{ width: '150px', height: 'auto' }}
              src='./identification.png'
            />
            <h3 className='text-xl font-bold mt-6'>Identification des GP</h3>
            <p className='mt-4 text-sm text-justify'>
              Sur notre plateforme, chaque GP est passé au crible avec des
              documents d'identité validés. Pas de place pour l'improvisation !
              Si un GP veut rejoindre l'aventure, il doit d'abord passer notre
              checkpoint de sécurité. Résultat ? Une expérience 100% sereine
              pour nos utilisateurs et des colis en toute confiance !
            </p>
          </div>
          <div className='w-full md:w-1/2 lg:w-1/4 mb-12 text-center p-4'>
            <img
              alt='Feature image'
              className='mx-auto'
              style={{ width: '150px', height: 'auto' }}
              src='./identification.png'
            />
            <h3 className='text-xl font-bold mt-6'>
              Identification des clients
            </h3>
            <p className='mt-4 text-sm text-justify'>
              Tout comme les GP, les expéditeurs de colis doivent également
              passer par un processus d'identification rigoureux. Cette mesure
              garantit la sécurité des transporteurs en les protégeant contre
              les risques liés au transport de produits non conformes à notre
              charte.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
