export function Contact() {
  return (
    <section className='py-20 bg-gray-100' id='contact'>
      <div className='container mx-auto px-6'>
        <h2 className='text-3xl font-bold text-center mb-12'>Contactez-nous</h2>
        <div className='max-w-lg mx-auto bg-white p-8 rounded shadow'>
          <form action='#' method='POST'>
            <div className='mb-6'>
              <label
                htmlFor='name'
                className='block text-sm font-medium text-gray-700'
              >
                Nom complet
              </label>
              <input
                type='text'
                id='name'
                name='name'
                className='mt-1 block w-full p-3 border border-gray-300 rounded'
                placeholder='Votre nom complet'
                required
              />
            </div>
            <div className='mb-6'>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-700'
              >
                Adresse e-mail
              </label>
              <input
                type='email'
                id='email'
                name='email'
                className='mt-1 block w-full p-3 border border-gray-300 rounded'
                placeholder='Votre adresse e-mail'
                required
              />
            </div>
            <div className='mb-6'>
              <label
                htmlFor='message'
                className='block text-sm font-medium text-gray-700'
              >
                Message
              </label>
              <textarea
                id='message'
                name='message'
                rows={4}
                className='mt-1 block w-full p-3 border border-gray-300 rounded'
                placeholder='Votre message'
                required
              ></textarea>
            </div>
            <div className='text-center'>
              <button
                type='submit'
                className='w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700'
              >
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
